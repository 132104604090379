import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { Button } from "../styles/Button"
import Image from "gatsby-image"

import { AboutSection } from "../styles/AboutStyles"
import {
  Image_Asset_1,
  MiniPies,
  Image_Asset_3,
  Image_Asset_4,
  Image_Asset_5,
  Image_Asset_6,
} from "../utils/imageUpload"

export const query = graphql`
  query {
    banner: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/BannerAbout/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <LandingAbout>
        <Image fluid={data.banner.nodes[0].childImageSharp.fluid}></Image>
      </LandingAbout>
      <AboutSection about="true" bg={"var(--beige)"} color={"var(--darkBlue)"}>
        <MainInfo>
          {" "}
          {/* <BodyWrapper phoneNo> */}
          <div className="container">
            <div className="content">
              <h1 className="title">QUALITY INGREDIENTS</h1>
              <h2 className="text">
                Savorly is made with European savoir-faire which stands for
                quality and taste. Our products are NONGMO and clean label, made
                with RSPO sustainably sourced Palm Oil.
              </h2>
            </div>
            <img src={Image_Asset_1} alt="" />
          </div>
          <div className="container">
            <img src={MiniPies} alt="" />
            <div className="content">
              <h1 className="title"> A FRESH SPIN ON FROZEN</h1>
              <h2 className="text">
                Savorly was created to bring the strong tradition of Europe's
                frozen appetizers to the US. In Europe it is common to gather
                with friends for "apero" in an atmosphere where food is shared.
                After carefully studying food trends in Europe and the US
                markets, we decided to enter the largely sleepy category of
                frozen appetizers with a bit of flair and innovation. We aim to
                redefine the frozen aisle in the US with a product that is easy
                to cook and serve, has approachable packaging, and offers an
                elevated experience to the consumer.
              </h2>
            </div>
          </div>
          <div className="container">
            <div className="content">
              <h1 className="title">ELEVATED TASTE</h1>
              <h2 className="text">
                We replaced the traditional frozen appetizer cheese goop and
                artificial preservatives with olives, ricotta, grilled eggplant,
                and all the other good stuff you’d prep with yourself. Our
                products are veggie forward and built on elevated flavor
                combinations.
              </h2>
            </div>
            <img src={Image_Asset_3} alt="" />
          </div>
          {/* </BodyWrapper> */}
        </MainInfo>
        <BlueBanner style={{ background: "var(--blue)", width: "100vw" }}>
          {/* <BodyWrapper> */}
          <div className="content">
            <div>
              <img src={Image_Asset_4} alt="" />
            </div>

            <h2 className="text" style={{ textAlign: "center" }}>
              We're two best friends who grew up in Belgium with a strong
              culture of frequent, casual gatherings. We both moved to Brooklyn
              in our early twenties and once here we noticed most of our friends
              got together or hosted parties at bars - leaving home
              entertainment to their parents around the holidays
            </h2>
          </div>
          {/* </BodyWrapper> */}
        </BlueBanner>

        <ThirdBanner>
          {/* <BodyWrapper> */}
          <div className="content-wrapper">
            <div className="item-container">
              <div>
                <img src={Image_Asset_5} alt="" />
              </div>
              <h2 className="text">
                We started Savorly to make it easier to get together and enjoy
                time with friends and family. Whether gathering around a holiday
                table, catching up over drinks and appetizers, or a weekly video
                chat - we believe that life is better when we're together
              </h2>
            </div>
            <div className="item-container">
              <div>
                <img src={Image_Asset_6} alt="" />
              </div>
              <h2 className="text">
                We created our line of party bites and gratins with convenience
                in mind, but are committed to quality. You can heat and serve
                Savorly products at a moment's notice without sacrificing on
                ingredients or experience for yourself or your guests
              </h2>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "50px 0",
            }}
          >
            <Link to="/shop">
              <Button bg="var(--orange)">Shop Now!</Button>
            </Link>
          </div>
          {/* </BodyWrapper> */}
        </ThirdBanner>
      </AboutSection>
    </Layout>
  )
}

export default About

const LandingAbout = styled.div``

const MainInfo = styled.div`
  padding: 70px 0;
  max-width: 60%;
  margin: 0 auto;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 50px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 40%;

    .title {
      font-weight: 600;
    }
    .text {
      font-weight: 400;
    }
  }

  img {
    width: 400px;
  }

  @media (max-width: 769px) {
    max-width: 95%;

    .container:first-child,
    .container:nth-child(3) {
      flex-direction: column-reverse;
    }
    .container {
      flex-direction: column;
      max-width: 90%;
      margin: 0 auto;
      padding: 20px 0;
    }

    .content {
      width: 100%;
    }

    .title {
      font-size: 1.2em;
    }
    .text {
      font-size: 1em;
    }

    img {
      width: 200px;
    }
  }
`

const BlueBanner = styled.div`
  .content {
    max-width: 60%;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    color: var(--beige);
    align-items: center;
    justify-content: space-between;

    img {
      width: 300px;
    }

    .text {
      width: 50%;
      font-weight: 400;
    }
  }

  @media (max-width: 769px) {
    .content {
      flex-direction: column;
      max-width: 90%;
      margin: 0 auto;
      .text {
        width: 100%;
      }
      img {
        width: 200px;
      }
    }
  }
`

const ThirdBanner = styled.div`
  max-width: 60%;
  margin: 0 auto;
  @media (max-width: 769px) {
    max-width: 95%;
    padding: 50px 0 10px 0;

    .content-wrapper {
      flex-direction: column;

      .item-container {
        padding: 30px 0;
      }
    }
  }
  padding: 150px 0 10px 0;
  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .item-container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .text {
      font-weight: 400;
      width: 80%;
    }
  }
  img {
    width: 200px;
    margin: 0;
    margin-bottom: 20px;
  }

  .shop-now {
    background-color: var(--darkBlue);
    border: none;
    display: inline-block;
    padding: 1em 2.5em;
    margin: 1em 0.3em 0.3em 0;
    border-radius: 0.5em;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: bolder;
    color: var(--white);
    text-align: center;
    transition: all 0.2s;
    letter-spacing: 0.2em;
    font-size: 1.2em;
    text-transform: uppercase;

    &:hover {
      background-color: var(--orange);
      color: white;
      cursor: pointer;
    }
  }
`
