import styled from "styled-components"
import { Section } from "./HomeStyles"
import { aboutLine } from "../utils/imageUpload"

export const AboutSection = styled(Section)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: 0;
  padding: 0;
  
  input {
    background-color: transparent;
    border: 1px solid var(--darkBlue);
    color: var(--darkBlue);
    padding: 5px 10px;
    font-weight: 300;
  }

  input[type="email"]:focus {
    outline: none;
    background-color: var(--white);
    color: var(--darkBlue);
  }

  input[type="button"] :hover {
    background-color: var(--darkBlue);
    color: var(--white);
    cursor: pointer;
    @media (max-width: 769px) {
      background-color: var(--white);
      color: var(--darkBlue);
    }
  }

  .emailQuery {
    display: table;
    margin-bottom: 6vh;
    width: 300px;
  }
  div.t {
    display: table-cell;
    width: 100%;
  }
  div.t > input {
    width: 100%;
  }
`

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;

  span {
    height: 28px;
    width: 100%;
    background-image: url(${aboutLine});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }

  @media (min-width: 700px) {
    width: 70%;
    height: 100%;
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
  /* margin: 20px; */

  p {
    font-size: 1.3em;
  }
`
